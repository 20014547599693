import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authCheck } from '../utils/auth';


function RouteGuard({ children }: any) {
    const navigate = useNavigate();

    // redirect to login page if accessing a private page and not logged in
    useEffect(()=>{
        const isAuthenticated = authCheck();
        if(!isAuthenticated){
            navigate("/login")
        }
        
    })

    return children;
}

export default RouteGuard;
