import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import { NextUIProvider } from '@nextui-org/react';
import LoginPage from './pages/login';
import HomePage from './pages';
import SignupPage from './pages/signup';
import ProfilePage from './pages/profile';
import RouteGuard from './components/routeGuard';
import Dashboard from './pages/dashboard';
import MyIndexes from './pages/my_indexes';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <NextUIProvider>
        <Routes>
          <Route path="/profile" element={<RouteGuard><ProfilePage /></RouteGuard>} />
          <Route path="/dashboard" element={<RouteGuard><Dashboard /></RouteGuard>} />
          <Route path="/my_indexes" element={<RouteGuard><MyIndexes /></RouteGuard>} />
          <Route path="/" element={<HomePage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/login" element={<LoginPage />} />
        </Routes>
    </NextUIProvider>
    </BrowserRouter>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
