import React from 'react';
import styled from 'styled-components';

const Annotation = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  min-height: 50px;
  background: #fff;
  text-align: center;
  font-size: 12px;

  img,
  a,
  p {
    display: inline-block;
    margin: 0;
  }

  img {
    margin-right: 10px;
  }

  a:link,
  a:visited {
    color: #a96851;
  }

  @media (min-width: 820px) {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    bottom: 30px;
    border-radius: 50px;
    padding: 10px;

    h1 {
      font-size: 60px;
    }
  }
`;

export default function SaltoBanner() {
  return (
    <Annotation>
      <p>
        SaltoGPT is a{' '}
        <a className="salto" href="https://www.linkedin.com/company/salto-analytics/">
          SaltoAnalytics
        </a>{' '}
        application that makes it easy to add context to ChatGPT.
      </p>
    </Annotation>
  );
}
